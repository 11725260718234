<template>
  <div class="setting_form">
    <create-deep-link ref="create_deep_link_modal"></create-deep-link>
    <div class="white_box h-full">
      <div class="body">
        <div v-if="getSelectedDeepLink.items && getSelectedDeepLink.items.length" class="body-section ">
          <div class="grid grid-cols-2 lg:grid-cols-3 gap-6 my-10 mx-10  "
               style=" grid-auto-rows: minmax(7.5rem, auto)">
            <div
              v-for="item in getSelectedDeepLink.items"
              class="grid gap-x-3 w-full rounded-2xl bg-[#F4F6FA] py-8 cursor-pointer "
              style="grid-template-columns: minmax(5.5rem, 5.5rem) auto"
              @click="createDeepLinkModal(item)"
            >
              <div class="pl-8">
                <img
                  class=" h-full w-full "
                  :src="item.social_icon"
                  alt=""
                />
              </div>
              <div class="grid grid-rows-2 pr-4 pt-2">
                <p
                  class="text-[#3C4549] text-[14px] font-bold font-poppins leading-none"
                >
                  {{ item.name }}
                </p>
                <p
                  class="text-[#757A8A] text-[12px] font-normal font-poppins leading-none"
                >
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CreateDeepLinkV2 from "@/views/pages/setting/DeepLinks/CreateDeepLinkV2";
// import CreateDeepLinkV2 from "./CreateDeepLinkV2.vue";

export default {
  name: "DeepLinksCommon",
  async created() {

  },
  components: {
    "create-deep-link": CreateDeepLinkV2,
  },
  computed: {
    ...mapGetters(["getSelectedDeepLink", "getDeepLinksSettingsList"]),
  },
  methods: {
    ...mapActions(["selectCategory", "setCreateDeepLink"]),
    createDeepLinkModal(item) {
      this.setCreateDeepLink(item);
      this.$refs.create_deep_link_modal.defaultSelectedBrand();
      this.$bvModal.show("modal-create-deep-link");
    },
  },
  watch: {
    getDeepLinksSettingsList: {
      async handler(newVal, oldVal) {
        await this.selectCategory(this.$route.name);

        // Unregister the watcher after once run
        this.$nextTick(() => {
          this.$options.watch.selectedDeepLink.handler = null;
        });
      },
      deep: true, // This watches for deep changes in the object
    },
  },
};
</script>
<style scoped lang="less">
.setting_form {
  .white_box {
    .body {
      display: block;
      overflow-y: auto;
      height: calc(100vh - 13.2rem);
    }
    .box_inner {
      .header {
        .deeplinks-alert {
          padding: 10px 20px;
          border-radius: 10px;
          background: #f2f6f7;

          p {
            font-weight: bold;
          }
        }

        .business-growth-activity {
          width: 100%;

          .side {
            background-image: linear-gradient(112deg, #43a6ff, #0078e4);
            border-radius: 10px;
            width: 49%;
            height: auto;
            padding: 20px;

            .head {
              h4 {
                color: white;
                font-weight: bold;
                margin-bottom: 10px;
              }

              p {
                color: white;
              }
            }

            .content {
              img {
                width: 120px;
              }
            }
          }

          .left {
            background-image: linear-gradient(112deg, #43a6ff, #0078e4);
          }

          .right {
            background-image: linear-gradient(112deg, #ff8082, #dc2124);
          }
        }
      }

      .body {
        .body-title {
          h6 {
            font-weight: bold;
            font-size: small;
          }

          p {
            font-size: small;
          }
        }

        .body-section {
          .apps {
            .block {
              border: 1px solid #d0d6e5;
              border-radius: 10px;
              padding: 15px 10px;
              cursor: pointer;
              min-height: 90px;

              .content {
                img {
                  width: 50px;
                }
              }

              .head {
                padding-left: 15px;

                h6 {
                  font-weight: bold;
                  font-size: small;
                }

                p {
                  font-size: small;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
